import { SocialSharingTags } from '@/components/molecules/SocialSharingTags';
import {
  SelectedModelsProps,
  EVComparisonLandingTemplate,
} from '@/components/template/EVComparisonLandingTemplate/EVComparisonLandingTemplate';
import { getPopularEVCompare } from '@/lib/getPopularEVCompare';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

export interface EVComparisonProps {
  makes: string[];
  popularEVCompare: SelectedModelsProps[][];
}

const EVComparison: React.FC<EVComparisonProps> = ({
  makes,
  popularEVCompare,
}) => {
  return (
    <div className="w-full">
      <SocialSharingTags
        title={SEOMetaTags.comparePage.title}
        description={SEOMetaTags.comparePage.description}
      />
      <EVComparisonLandingTemplate
        makes={makes}
        selectedModelsCompare={popularEVCompare}
      />
    </div>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  let makes = [];
  const makesResponse = await fetch(
    `${process.env.API_ENDPOINT}/api/build/makes`
  );
  if (makesResponse.ok) {
    const makesJson = await makesResponse.json();
    makes = makesJson.Makes;
  }
  const fetchedEVCompare = await getPopularEVCompare();
  const popularEVCompare = fetchedEVCompare.map(
    (subArray: SelectedModelsProps[][]) =>
      subArray.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key.charAt(0).toLowerCase() + key.slice(1),
            value,
          ])
        )
      )
  );

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
      makes,
      popularEVCompare,
    },
    revalidate: 60 * 60 * 24,
  };
};

export default EVComparison;
